body {
  margin: 0; 
  margin-bottom: 185px;

  color: #000000;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  cursor: default;
  background-color: #ffffff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.year-button{
  background-color: transparent !important;
  color: #661c1c !important;
  border: 1px solid #661c1c !important;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.year-button-enabled{
  background-color: #661c1c !important;
  color: white !important;
  border: 1px solid black !important;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}
.limit-button{
    background-color: #661c1c !important;
    color: white !important;
    border: 1px solid black !important;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}
.limit-button:hover { background-color: #4f2116 !important; }

.inner_row { margin: 0; }

/* ========================================================================
 * SPECIAL CLASSES
 * ======================================================================== */
 .container:before,
 .container:after,
 .row:before,
 .row:after,
 .inner_row:before,
 .inner_row:after,
 .nav:before,
 .nav:after,
 .navbar:before,
 .navbar:after,
 .navbar-collapse:before,
 .navbar-collapse:after,
 .modal-footer:before,
 .modal-footer:after {
   display: table;
   content: " ";
 }
 .container:after,
 .row:after,
 .inner_row:after,
 .nav:after,
 .navbar:after,
 .navbar-collapse:after,
 .modal-footer:after {
   clear: both;
 }
 

 .header{
    color: #ffffff;
    background-color: #000000;
 }

 .footer{
    color: #000000;
    background-color: #e0e0e0;
    position: relative;
    bottom: 0;
    left: 0;
    float: left;
    width: 100%;
    height: 180px;
    margin: 0;
    padding: 15px 20px;
    background-color: #e0e0e0;
    /*height: 150px;*/
 }

.text_logo { padding: 10px 0; }
.text_logo h1 {
	font-size: 22px;
	font-family: 'Questrial', sans-serif;
	text-transform: uppercase;
}
.text_logo p { font-size: 14px; }

.text-right { text-align: right; }
.text-left { text-align: left!important; }

.login_btn {
	margin-top: 8px !important;
	padding: 10px !important;
	color: #ffffff !important;
	font-size: 13px !important;
	background-color: #000000 !important;
	border: none !important;
}

.material-icons {
    margin-top: -3px;
    vertical-align: middle;
}

.text-center {
  text-align: center;
}

.max-width{
  max-width: 100% !important;
}
.text-right {
  text-align: right !important;
}

.contact_info li.title {
	position: relative;
	margin-bottom: 25px;
	font-size: 16px;
	font-weight: 400;
}
.contact_info li.title:after {
	content: "";
	display: block;
	position: relative;
	left: 0;
	bottom: -4px;
	width: 50px;
	height: 3px;
	background-color: #661c1c;
}

.contact_info li:not(.title) {
	margin: 10px 0;
	font-size: 14px;
	font-weight: 400;
}
.contact_info li i.material-icons {
	margin-right: 5px;
	color: #661c1c;
	font-size: 22px;
}

.social_icons { margin: 15px 0 0; }
.social_icons a {
	display: inline-block;
	margin-left: 10px;
	padding: 4px 5px 2px;

	background-color: #661c1c;
	border-radius: 4px;
}
.social_icons a:hover { background-color: #3c0716; }
.social_icons a img { width: 25px; }

.owner { font-weight: 400; }
.owner img { margin-left: 5px; height: 58px; vertical-align: middle; }

nav.footer_links { margin: 15px 0; }
nav.footer_links a {
	display: inline-block;
	font-size: 13px;
	font-weight: 400;
}
nav.footer_links a:not(:last-child) { margin-right: 8px; }
nav.footer_links a:not(:last-child):after {
	content: "|";
	display: inline-block;
	margin-left: 8px;
}

ul {margin: 0;padding: 0;list-style-type: none;}

a { color: #0f5f72 !important; cursor: pointer; }
a:visited { color: #0f5f72 !important; }
a:hover { text-decoration: underline; }

.accordion-button:not(.collapsed) {
  color: #ffffff !important;
  background-color: #0f5f72 !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  color: #ffffff !important;
}

.accordion{
  --bs-accordion-btn-active-icon: url('./images/drop_down.svg') !important;
  --bs-accordion-active-color: #ffffff !important;
}

.nav-link.active{
  color: #ffffff !important;
  background-color: #0f5f72 !important;
}

.accordion-body{
  background-color: #e8e8e8 !important;
}

.info-table{
  background-color: #e8e8e8 !important;
}

@media all and (height < 700px) {
  .footer{
    position: relative;
    height: auto;
  }
}

@media all and (width < 500px) {
  .footer{
    position: relative;
    height: auto;
  }
}



@media all and (max-width: 530px) {
	footer div[class^="col-xs"] { width: 100%; margin: 0; }

	footer { height: 215px; }
	.contact_info { margin-bottom: 15px; }
	.contact_info li.title { font-size: 14px; }
	.contact_info li:not(.title) { font-size: 13px; }
	.contact_info li i.material-icons { font-size: 20px; }

	.owner { display: inline-block; vertical-align: middle; }
	.social_icons { display: inline-block; vertical-align: middle; margin: 0; }
}
@media all and (max-width: 440px) {
	footer { height: 247px; }
	.owner { display: block; }
	.social_icons { display: block; margin-top: 5px; }
}

.table-unit{
  font-size: smaller;
}

.loading-background{
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.notes-footer{
  font-size: small !important;
}